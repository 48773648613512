// import store from '@/store'
import request from '@/utils/request'

export const login = async (code) => {
  return request({
    url: '/id/discord',
    method: 'post',
    params: {
      code
    }
  })
}
