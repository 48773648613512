<template>
  <div class="columns justify-center">
    <h1>Authorizing...</h1>
  </div>
</template>

<script>
import { login } from '@/api/id'

export default {
  name: 'Id',
  mounted () {
    const { code } = this.$route.query
    if (!code) return this.$router.push({ name: 'Home' })
    login(code)
      .then(async res => {
        await this.$store.dispatch('user/login', res)
      })
      .catch(res => {
        console.log('CALLBACK', res)
        if (res.statusCode === 406) {
          console.log(res)
          this.$buefy.notification.open({
            message: res.message,
            type: 'is-danger'
          })
        }
      })
      .then(() => this.$router.push({ name: 'Home' }))
  }
}
</script>

<style scoped>

</style>
